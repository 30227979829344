<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <Spinner :spin="spin" />
      <section class="header activity_question">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
        <!-- <span class="fL">{{ title }}</span> -->
        <div class="icon iconButton"></div>
      </section>
      <!-- Main -->
      <section class="main activity_question">
        <div class="flexV w-full">
          <img :src="qnaCampaign.imageUrl" class="w-full rounded-2xl" alt="" />
          <span class="fL third mt-2">{{ qnaCampaign.campaignName }}</span>
          <div class="flex items-center justify-between mt-1">
            <span class="fM gray p-2 badge-label">{{
              qnaCampaign.departmentName
            }}</span>
            <span class="fM gray">
              {{ tsToDatetime(qnaCampaign.campaignStartTime) }}
            </span>
          </div>
        </div>
        <span class="fM mt-2" v-html="qnaCampaign.campaignDesc"></span>
        問答獎勵：雲林幣{{ qnaCampaign.reward_point }}枚
        <div class="text-red-500 mt-1">剩餘總數以實際完成任務當下判定</div>
        <div class="flex">
          <button
            class="button text-white border-0 flex-1 fM float rounded-2xl"
            @click="join"
            :disabled="btnLabel !== '快來搶獎勵'"
          >
            {{ btnLabel }}
          </button>
        </div>
      </section>
      <!-- Popup (答案錯誤) -->
      <section id="popWrong" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="againButton()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="icon">
              <font-awesome-icon
                icon="fa-regular fa-circle-xmark"
                size="2x"
              ></font-awesome-icon>
            </div>
            <span class="fL">答案錯誤</span>
            <span class="fM gray">請再試一次</span>
          </section>
          <section class="popFooter">
            <a class="button submit rounded" @click.prevent="againButton()"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>
      <!-- Popup (答案正確) -->
      <section id="popCorrect" class="popCorrect popup activity_question">
        <div class="popBody">
          <a class="close" @click.prevent="goBackTo">
            <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
          </a>
          <section class="popHeader">
            <div class="img">
              <img src="@/assets/icon/point-w.svg" />
            </div>
          </section>
          <section class="popMain">
            <span class="fM gray">恭喜您獲得</span>
            <div class="flexH">
              <div class="img">
                <img src="@/assets/icon/point.svg" />
              </div>
              <span class="fXL">{{ recivePoints }}</span>
            </div>
            <span class="fS gray">來自</span>
            <span class="fL gray">{{ title }}</span>
          </section>
          <section class="popFooter">
            <div class="flexH width">
              <a
                class="flexV width center"
                @click.prevent="$router.push('/record')"
              >
                <span class="fS gray">我的雲林幣</span>
                <div class="flexH">
                  <div class="img">
                    <img src="@/assets/icon/point.svg" />
                  </div>
                  <span class="fM bold">{{ dipslayUserPointBalance() }}</span>
                </div>
              </a>
              <div class="lineV"></div>
              <a
                class="flexV width center"
                @click.prevent="$router.push('/notification')"
              >
                <span class="fS gray">通知中心</span>
                <NotificationBell />
              </a>
            </div>
          </section>
        </div>
      </section>

      <!-- Popup (答案正確無獎勵) -->
      <section id="popNoReward" class="popCorrect popup activity_question">
        <div class="popBody">
          <a class="close" @click.prevent="goBackTo">
            <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
          </a>
          <section class="popHeader">
            <div class="img">
              <img src="@/assets/icon/point-w.svg" />
            </div>
          </section>
          <section class="popMain">
            <span class="fXL">感謝參與回答</span>
            <hr />
            <!-- <span v-if="rewardCompleted" class="fM gray">獎勵已全數發完</span> -->
            <!-- <span v-else class="fM gray">您已領取過獎勵</span> -->
            <span class="fM gray">獎勵已全數發完</span>
            <span class="fM gray">來參加看看其他活動吧！</span>
          </section>
          <!-- <section class="popFooter">
                    <div class="flexH width">
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/checkin.svg" />
                            </div>
                            <span class="fS">每日簽到</span>
                        </a>
                        <div class="lineV"></div>
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/game.svg" />
                            </div>
                            <span class="fS">小遊戲</span>
                        </a>
                        <div class="lineV"></div>
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/video.svg" />
                            </div>
                            <span class="fS">看影片</span>
                        </a>
                    </div>
                </section> -->
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import NotificationBell from "@/components/NotificationBell.vue";
import Spinner from "@/components/Spinner";

export default {
  name: "QA",
  components: {
    NotificationBell,
    Spinner,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      questionInfo: {
        questions: [],
      },
      answer: "",
      prize: 10,
      recivePoints: 0,
      getCampaignInfoUrl: "",
      campaignSubmitUrl: "",
      qtnContext: {},
      answers: {},
      campaigns: [],
      countUnread: 0,
      isParticipatedQA: false,
      qnaCampaign: {},
      qnaCampaignId: "",
      spin: true,
      isProcessing: false,
      isInitialized: false,
      publisher: this.$route.meta.publisher,
      remainTime: 3,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //     // called before the route that renders this component is confirmed.
  //     // does NOT have access to `this` component instance,
  //     // because it has not been created yet when this guard is called!
  //     console.log(to, from);
  //     next();
  // },
  created() {
    let vm = this;
    let that = vm;
    let campaignId = this.$route.params.id;

    // if (!(that.user && that.user.userId)) {
    // this.$router.push("/error");
    // } else {
    that
      .getCampaigns()
      .then(() => {
        that.qnaCampaign = that.campaigns.find(
          (campaign) => campaign.campaignId == campaignId
        );
        console.log("qnaCampaign: ", that.qnaCampaign);
        // if (
        //     that.qnaCampaign &&
        //     that.checkIsParticipatedQA(that.qnaCampaign)
        // ) {
        //     this.$store.commit("updateIsFinishQuestions", true);
        //     this.$router.push(`/home`);
        //     return;
        // }
        if (!that.qnaCampaign || Object.keys(that.qnaCampaign).length == 0) {
          // this.$store.commit("updateIsQANotExist", true);
          // this.$router.push(`/error`);
          return;
        }
        that.spin = false;
        // that.campaignStart(campaignId)
        //     .then((res) => {
        //         console.log('aaaaaa', res)
        //         that.getCampaignInfoUrl = res.getInfoUrl;
        //         that.campaignSubmitUrl = res.submitUrl;
        //         that.qtnContext = res.context;
        //         that.getQuestions()
        //             .then((getQuestionsRes) => {
        //                 Object.assign(
        //                     that.questionInfo,
        //                     getQuestionsRes
        //                 );
        //                 that.spin = false;
        //                 that.isInitialized = true;
        //             })
        //             .catch((err) => {
        //                 console.log("err: ", err);
        //                 this.$router.push("/error");
        //             });
        //     })
        //     .catch((err) => {
        //         console.log("err: ", err);
        //         this.$router.push("/error");
        //     });
      })
      .catch((getCampaignsErr) => {
        console.log("getCampaignsErr: ", getCampaignsErr);
        // this.$router.push("/error");
      });
    // }
  },
  mounted() {
    this.remainTime = this.checkAnswerTime();
  },
  computed: {
    ...mapState(["user", "userInfo", "isQANotExist"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
    }),

    btnLabel() {
      return this.qnaCampaign.reward_status
        ? "您已領取獎勵"
        : this.qnaCampaign.reward_point > 0 &&
          this.qnaCampaign.reward_remain <= 0
        ? "獎勵已搶完"
        : "快來搶獎勵";
    },
    questions() {
      return this.qnaCampaign.missions[0].settings.questions;
    },
    rewardCompleted() {
      return (
        this.qnaCampaign &&
        this.qnaCampaign.campaignBudget &&
        this.qnaCampaign.campaignBudget.availablePoints <= 0
      );
    },
    title() {
      return this.publisher == "municipal" ? "市政問答" : "問答";
    },
  },
  methods: {
    checkAnswerTime() {
      let answerTimes = localStorage.getItem("answerTimes");
      if (!answerTimes) {
        answerTimes = [{ campaignId: this.$route.params.id, remainTime: 3 }];
        localStorage.setItem("answerTimes", JSON.stringify(answerTimes));
      }
      console.log("qwe", answerTimes);
      return (
        answerTimes &&
        JSON.parse(answerTimes).find(
          (t) => t.campaignId == this.$route.params.id
        )?.remainTime
      );
    },
    join() {
      const url =
        (this.$route.fullPath.includes("b1") ? "/b1-3/" : "/service/qa/") +
        this.qnaCampaign.campaignId;
      this.$router.push(url);
    },
    dipslayUserPointBalance() {
      return this.userPoints.pointBalance;
    },
    changeAnswer(_, questionId) {
      this.answers[questionId] = this.questions.find(
        (q) => q.id == questionId
      )?.fillinAnswer;
      this.answers = { ...this.answers };
    },
    tsToDate(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD");
    },
    dummyGetCampaigns() {
      let result = {
        uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
        memberId: 208,
        campaigns: [
          {
            campaignId: "M_RdROw7eY5B",
            campaignName: "測試幸運輪盤任務",
            campaignDesc: "輪盤抽獎。Yay!!",
            campaignStatus: "approved",
            campaignPrototype: "minigame",
            campaignStartTime: 1652284800,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652322816,
            missions: [
              {
                missionId: 44,
                missionName: "幸運輪盤（小遊戲任務）",
                missionDesc: "As title",
                participation: [
                  {
                    data: {
                      rewardIds: ["38"],
                    },
                    status: "completed",
                    completedAt: 1652341991,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_r5PDKD0m58",
            campaignName: "PrePOB測試任務：抽獎小遊戲",
            campaignDesc: "As title",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1652112000,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652167000,
            missions: [
              {
                missionId: 36,
                missionName: "抽獎小遊戲",
                missionDesc: "好禮大方送",
                participation: [
                  {
                    data: {
                      rewardIds: ["31"],
                    },
                    status: "completed",
                    completedAt: 1652170978,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_xd4wXAwvkj",
            campaignName: "PrePOB測試任務：推薦",
            campaignDesc:
              "To recommend or not to recommend, that is the question.",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1652025600,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652070487,
            missions: [
              {
                missionId: 31,
                missionName: "測試推薦任務 之 推薦人關卡",
                missionDesc: "推薦他人成功可以領取獎項",
                participation: [
                  {
                    status: "completed",
                    completedAt: 1652092171,
                  },
                  {
                    status: "completed",
                    completedAt: 1652092477,
                  },
                ],
              },
              {
                missionId: 32,
                missionName: "推薦人關卡",
                missionDesc: "推薦他人成功可以領取獎項",
              },
              {
                missionId: 34,
                missionName: "被推薦人關卡",
                missionDesc: "接受他人推薦連結並成功註冊者可以領取獎項",
              },
              {
                missionId: 35,
                missionName: "註冊關卡",
                missionDesc: "成功註冊者可以領取獎項",
              },
            ],
          },
          {
            campaignId: "M_p5O76qR854",
            campaignName: "註冊任務",
            campaignDesc: "成功註冊即可獲得100點",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1651132800,
            campaignEndTime: 1656575999,
            campaignCreatedAt: 1651129632,
            missions: [
              {
                missionId: 22,
                missionName: "會員註冊任務",
                missionDesc: "只要成功註冊會員即可獲得，每人限領一次",
                participation: [
                  {
                    data: {},
                    status: "completed",
                    completedAt: 1652253394,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_qkwQ9ygK5L",
            campaignName: "2022問答任務debug測試",
            campaignDesc: "答對有獎，限量三名",
            campaignStatus: "approved",
            campaignPrototype: "test",
            campaignStartTime: 1650816000,
            campaignEndTime: 1656575999,
            campaignCreatedAt: 1650862201,
            missions: [
              {
                missionId: 16,
                missionName: "問答任務 測試",
                missionDesc: "有獎問答 測試",
                participation: [
                  {
                    status: "completed",
                    completedAt: 1651387810,
                  },
                ],
              },
            ],
          },
        ],
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    getCampaignAPI() {
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/yunlin/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal`,
        method: "GET",
      };
      return this.$http(config);
    },
    getCampaigns() {
      return (
        this.getCampaignAPI()
          // this.campaignDummy()
          .then((res) => {
            this.campaigns = res.data.campaigns;
            console.log("campaigns: ", this.campaigns);
            return res;
          })
      );
    },
    campaignStart(campaignId) {
      return fetch(
        `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${campaignId}/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}/run`,
        { method: "GET" }
      )
        .then(function (response) {
          return response.json();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getQuestions() {
      let config = {
        url: this.getCampaignInfoUrl,
        method: "GET",
        params: {
          uuid: this.user.userId,
        },
      };
      return this.$http(config)
        .then((res) => {
          console.log("questions: ", res);
          return res.data;
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    },
    dummyWrongAnswer() {
      let result = {
        success: false,
        evaluation: {
          corrects: [],
          incorrects: [
            {
              id: 10,
              prompted: "A",
              expected: "C",
            },
          ],
          score: 0,
          totalScore: 1,
          passingScore: 1,
          hasPassed: false,
          correctness: 0,
        },
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    dummyCorrectAnswer() {
      let result = {
        success: true,
        evaluation: {
          corrects: [
            {
              id: 10,
              prompted: "C",
              expected: "C",
            },
          ],
          incorrects: [],
          score: 1,
          totalScore: 1,
          passingScore: 1,
          hasPassed: true,
          correctness: 1,
        },
        claimRewardResults: [
          {
            merchantId: "tycard2022",
            campaignId: "M_PkJYrY4mbM",
            missionId: 14,
            memberId: 208,
            uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
            requests: {
              12: false,
            },
            rewardId: 12,
            rewardName: "問答任務完成獎品100點",
            campaignBudgetId: 16,
            context: {
              name: "點數",
              amount: 100,
            },
            status: true,
            data: {
              points: 100,
              transactionId: "47f28c3ac01dfbbf535c6bb58f89cf96c7ce4a3d",
              transactionStatus: "success",
              transactionType: "Activity-Reward",
            },
          },
        ],
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    dummyNoReward() {
      let result = {
        success: true,
        evaluation: {
          corrects: [
            {
              id: 10,
              prompted: "C",
              expected: "C",
            },
          ],
          incorrects: [],
          score: 1,
          totalScore: 1,
          passingScore: 1,
          hasPassed: true,
          correctness: 1,
        },
        claimRewardResults: [
          {
            status: false,
            rewardId: 12,
            rewardName: "問答任務完成獎品100點",
            campaignBudgetId: 16,
            context: {
              name: "點數",
              amount: 100,
            },
            error: {
              code: "RW105",
              message: "獎項不允許重複領取",
            },
          },
        ],
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    submit() {
      if (this.isProcessing === true) return;
      this.isProcessing = true;

      var data = JSON.stringify({
        uuid: this.user.userId,
        answers: this.answers,
        evaluateImmediately: true,
        context: this.qtnContext,
      });

      var config = {
        method: "post",
        url: this.campaignSubmitUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // return this.dummyNoReward(config)
      var self = this;
      return this.$http(config)
        .then(
          function (response) {
            console.log("submit: ", response.data);
            if (this.checkIsCorrect(response.data)) {
              this.parseRewards(response.data);
              console.log("Correct!");
              this.getUserPoints()
                .then((getUserPointsRes) => {
                  this.$store.commit("updateUserPoints", getUserPointsRes);
                  this.doActionByClaimRewardType(response.data);
                })
                .catch((err) => {
                  console.log("err: ", err);
                  this.errorHandler();
                });
            } else {
              console.log("Wrong!");
              this.wrongAction();
              this.isProcessing = false;
            }
            return response.data;
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error.response.data);
          const code = error.response.data.code;
          if (code == "QTN101") {
            //已成功作答過
            self.noRewardAction();
          } else if (code == "QTN102") {
            //答案有誤
            self.wrongAction();
            self.isProcessing = false;
          }
          // self.errorHandler();
        });
    },
    doActionByClaimRewardType(responseData = {}) {
      let reward =
        responseData.claimRewardResults &&
        responseData.claimRewardResults.length > 0
          ? responseData.claimRewardResults[0]
          : [];
      let status = reward.status;
      if (status) {
        this.correctAction();
      } else {
        this.noRewardAction();
      }
    },
    correctAction() {
      var popCorrect = document.getElementById("popCorrect");
      popCorrect.style.display = "flex";
    },
    wrongAction() {
      var popWrong = document.getElementById("popWrong");
      popWrong.style.display = "flex";
    },
    noRewardAction() {
      var popWrong = document.getElementById("popNoReward");
      popWrong.style.display = "flex";
    },
    againButton() {
      var popWrong = document.getElementById("popWrong");
      popWrong.style.display = "none";
    },

    getUserPoints() {
      var config = {
        method: "get",
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    parseRewards(responseData = {}) {
      let reward =
        responseData.claimRewardResults &&
        responseData.claimRewardResults.length > 0
          ? responseData.claimRewardResults[0]
          : [];
      let status = reward.status;
      if (status) {
        this.recivePoints =
          reward.data && reward.data.points ? reward.data.points : 0;
      } else {
        this.recivePoints = 0;
      }
    },
    checkIsCorrect(responseData = {}) {
      let hasPassed = responseData.evaluation.hasPassed;
      return hasPassed;
    },
    checkIsParticipatedQA(campaign) {
      let mission =
        campaign.missions && campaign.missions.length > 0
          ? campaign.missions[0]
          : {};
      if (Object.keys(mission).length > 0) {
        let participation = mission.participation
          ? mission.participation[0]
          : {};
        if (participation.status === "completed") {
          return true;
        }
      }
      return false;
    },
    errorHandler() {
      window.location.reload();
    },
    goBackTo() {
      this.$router.go(-1);
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
    getValidationRules() {
      return "required";
    },
    showValidationRule(question) {
      const { settings, type } = question;
      if (type === "fill-text" && !settings.required) return;
      if (type === "multi-choice") {
        const min = settings.minSelection || 1;
        const max = settings.maxSelection;
        return `(最少須選取${min}個${max ? `，最多可選取${max}個` : ""})`;
      }
      return "(必填)";
    },
    checkCheckboxDisabled(question, optionValue) {
      const { type, fillinAnswer, settings } = question;
      if (type !== "multi-choice" || !settings.maxSelection) return;
      return (
        fillinAnswer.length >= settings.maxSelection &&
        fillinAnswer.indexOf(optionValue) === -1
      );
    },
    checkUserAnswer(question, optionValue) {
      let checked = false;
      if (question.userAnswers) {
        for (let i = 0, len = question.userAnswers.length; i < len; i++) {
          if (question.userAnswers[i].answer === optionValue) {
            checked = true;
            break;
          }
        }
      }
      return checked;
    },
  },
};
</script>

<style scoped>
.body .activity_question.main {
  padding: 0 !important;
}
.body {
  background: #fff;
}
.body .button.float {
  width: calc(100% - 40px);
  margin: 0 auto;
}
</style>
